
import React, { useEffect, useContext,useState,useCallback,useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import SEO from "../../components/custom/SEO";
import { AiOutlineClose } from 'react-icons/ai'; // Importing close icon from React Icons
import useTracking from '../../components/custom/useTracking';
// This is a dynamic import for the 'react-input-range' library, specifically used in the Next.js framework. 
// It prevents server-side rendering for this component which may be necessary for components that can't be rendered server-side.


// Imports necessary style sheets and context hooks.
import RangeSlider from "../../components/custom/RangeSlider"
import { useJobId } from "../../contexts/ContextJbo";
import Products from "../../components/ProductsGrid/ProductsDefault";
import { FaFilter, FaList, FaThLarge, FaTh } from 'react-icons/fa';
import { useRouter } from 'next/router'
import { useAxios } from "../../utils/axios";
import { CurrencyContext } from '../../contexts/CurrencyContext';
import {ThemeContext} from "../../contexts/ThemeContext";
import { UserContext } from '../../contexts/UserContext';
import Pagination from '../../components/Pagenation';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BUILDER_URL } from '../../utils/config';
import { useDeviceSize } from '../../utils/useDeviceSize';
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import ErrorBoundary from '../../components/ErrorBoundary';
import { setImageSrc } from '../../utils/config';
import CustomPopup from "../../components/CustomPopup"; // Import CustomPopup
import { CgViewMonth } from "react-icons/cg";
import errorLogApi from '../../components/custom/ErrorLogApi';
import {ProductNotFound} from '../../pages/404';
import { RedisContext } from "../../contexts/RedisContext";
import MobileSearchBar from "../../components/Search/MobileSearchBar";



// import styles from  "../styles/Layout.module.css"
const BASE_URL = BUILDER_URL;
const Shop = ({ initialSEOData }) => {
// State hook to manage the currently open section. Initial value of -1 indicates that no sections are open
const [openSection, setOpenSection] = useState(-1);

// State hooks to manage pagination, initial current page and total pages is set to 1

const [currentPage, setCurrentPage] = useState(1);
const [pageCount, setPageCount] = useState(1);
const [page, setPage] = useState(2);
const trackPage = useTracking('products list Page');
const {redisProductPage} = useContext(RedisContext);
// State hook to manage the quantity of products per page, initially set to 12
const [quantity, setQuantity] = useState(12);

// State hook to store total number of products returned by the API
const [totalProducts, setTotalProducts] = useState("");

// State hook to manage visibility of the "Show more" button for each filter
const [isShowingMore, setIsShowingMore] = useState({});

// A custom hook for using Axios in our component
const axios = useAxios();

// Context hook to get the current selected currency and formatted currency
const { currentCurrency } = useContext(CurrencyContext);

//Accordian open state

// Custom hook to get Job Id
const { jboId } = useJobId();
const [isInitialLoading, setIsInitialLoading] = useState(true);
// State hook to manage the list of products
const [products,setProducts]=useState([]);
const [bannerImage,setBannerImage]=useState('');
// State hook to manage the loading state of the component
const [isLoading, setIsLoading] = useState(true);
const [filterLoading, setFilterLoading] = useState(true);
const { isMobile, isTablet } = useDeviceSize();

// State hook to manage the loading state of the component
const [isLoadingIcon, setisLoadingIcon] = useState(false);

// State hook to manage the view mode, which can be 'grid' or 'list'
const [view, setView] = useState('grid');

// State hooks to manage filter ranges for price and weight
const [value, setValue] = useState({ min: 0, max: 1000 });
const [weight, setWeight] = useState({ min: 0, max: 500 });

// State hook to manage the number of products to display, initially set to 48
const [productsToDisplay, setProductsToDisplay] = useState(48);
const [filterValuesData, setFilterValuesData] = useState([]);

// State hook to manage sorting order


// State hooks to manage filters data and current filter values
const [filters, setFilters] = useState([]);
const [filterValues, setFilterValues] = useState({});

const [SEOData, setSEOData] = useState(initialSEOData);
// State hook to manage the number of filter items to show for each filter
const [itemsShown, setItemsShown] = useState({});

// State hook to manage which filters are currently open
const [openFilters, setOpenFilters] = useState(filters.map(f => f.filter_name));

const [openAccordian,setOpenAccordian]=useState(false);
// State hook to manage selected filter values
const [redirectionStatus, setRedirectionStatus] = useState("");
// redis updated
const [productSettings, setProductSettings] = useState(redisProductPage || null);
// //console.log(productSettings);
const initialDisplayCount = 10;
const [displayCount, setDisplayCount] = useState(initialDisplayCount);
const [displayCounts, setDisplayCounts] = useState({});
const [selectedTags, setSelectedTags] = useState({});
const [filterTypeName, setFiltertTypeName]= useState();
const [stickyTop, setStickyTop] = useState(0);
const [initialLoading, setInitialLoading] = useState(false);
const [status,setStatus]=useState(false);
const [notfoundStatus,setNotFoundStatus] = useState(false);
const [selectedQuantitiy,setSelectedQuantitiy] = useState(48);
const [sortOptions, setSortOptions] = useState([]);
const [selectedSorting, setSelectedSorting] = useState('');
const [isPopupOpen, setPopupOpen] = useState(false);
const [popupConfig, setPopupConfig] = useState({});
const { menuData,sortByData } = useContext(UserContext);



  // Get the current slug (excluding base URL and query parameters)
  const currentUrl = new URL(window.location.href).pathname;


  // Recursive function to find the matching ID
  const getIdFromSlug = (slugUrl, menuArray) => {
    for (const item of menuArray) {
      // Match the current item's URL
      if (item.url.split("?")[0] === slugUrl) {
        return item.id;
      }

      // If child elements exist, recursively search within them
      if (item.child) {
        const childMatch = getIdFromSlug(slugUrl, item.child);
        if (childMatch) {
          return childMatch;
        }
      }
    }
    return null; // Return null if no match is found
  };

  // Fetch ID based on the current slug URL
  const matchingId = getIdFromSlug(currentUrl, menuData);



  // Filter `sortByData` based on `menu_id` and `is_default`
  const filteredSortData = sortByData?.filter(
    (sortItem) => sortItem.menu_id == matchingId && sortItem.is_default == 1
  );



  // Extract `sort_name` or use default value
  const sortName =
    filteredSortData?.length > 0
      ? filteredSortData[0]?.sort_name // Use the first (and only) match
      : "latest"; // Default value if no match is found


useEffect(() => {
  const handleScroll = () => {
    const aboveContent = document.getElementById('aboveContent');
    const bounding = aboveContent.getBoundingClientRect();
    setStickyTop(bounding.height);
  };

  window.addEventListener('scroll', handleScroll);
  
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };

}, []);
// Custom Pop up function 
const openPopup = (config) => {
  setPopupConfig(config);
  setPopupOpen(true);
};

const closePopup = () => {
  setPopupOpen(false);
};
   
//  This function takes a string with a range (separated by '-') as input and returns an object with 'min' and 'max' properties, both parsed to integers. If the input isn't a string or is falsy, it returns null.
function parseRange(rangeString) {
  if (!rangeString || typeof rangeString !== 'string') return null;
  const [min, max] = rangeString.split('-');
  return { min: parseInt(min, 10), max: parseInt(max, 10) };
}
const handleDisabledClick = (event) => {
  if (event) event.preventDefault(); // Only call preventDefault if event is provided

  // Display a Sweet Alert message to inform the user
  openPopup({
    type: 'info',
    title: 'Preview Mode',
    subText: 'Actions like apply filters are disabled during preview.',
    onConfirm: closePopup,
    onClose: closePopup,
    showConfirmButton: false,
      showCancelButton: false,
      cancelLabel: "close",
    autoClose: 4,
  });
};
const handleDisabledClick1 = (event) => {
  if (event) event.preventDefault(); // Only call preventDefault if event is provided

  // Display a Sweet Alert message to inform the user
  openPopup({
    type: 'info',
    title: 'Preview Mode',
    subText: 'Actions like Sorting Products are disabled during preview.',
    onConfirm: closePopup,
    onClose: closePopup,
    showConfirmButton: false,
      showCancelButton: false,
      cancelLabel: "close",
    autoClose: 4,
  });
};
// debounce function for api repeat - 20-08-2024
function debounce(func, wait) {
  let timeout;
  return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
  };
}



const router = useRouter();
  const previousUrl = useRef(router.asPath);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (url !== previousUrl.current) {
        previousUrl.current = url;
        window.location.reload();
      }
    };

    const handlePopState = () => {
      if (window.location.href !== previousUrl.current) {
        previousUrl.current = window.location.href;
        window.location.reload();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    window.addEventListener('popstate', handlePopState);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [router]);






const { slug = [] } = router.query;


// This function handles clicks on a "Show more" button related to product filters. It updates the number of filter items shown by adding 5 more items to the currently shown count.
function handleShowMore(filterName) {
  setItemsShown(prevItemsShown => ({
    ...prevItemsShown,
    [filterName]: (prevItemsShown[filterName] || 5) + 5 // Show 5 more items
  }));
}
const theme = useContext(ThemeContext);




useEffect(() => {
  
    trackPage();
}, [theme]);
// Run this function when the component mounts or when the URL changes
const searchQuery = window.location.search;
const initializeFiltersFromURL = () => {
  // Get the search query from the URL (e.g., '?f=Motifs:Abstract,Fish,Coins&sort=product_name_desc')
  
  // Parse the search query to get the filter values and sort option
  const params = new URLSearchParams(searchQuery);
  const filterParamPage = params.get('p');
  if(filterParamPage){
    const pageNumber = Number(filterParamPage);

    setCurrentPage(pageNumber)
    }

    const filterParamPageCount = params.get('pc');
    if(filterParamPageCount){
  
      setProductsToDisplay(filterParamPageCount)
      setSelectedQuantitiy(filterParamPageCount);

      }

  const filterParam = params.get('f');
  const sortParam = params.get('sort') || sortName;

  // Initialize an object to hold the filter values
  let initialFilterValues = {};
  
  // Parse the filter parameters and set the initial filter values
  if (filterParam) {
    filterParam.split('::').forEach(param => {
      const [key, value] = param.split(':');
      if (key && value) {
        initialFilterValues[key] = value.includes(',') ? value.split(',') : [value];
      }
    });
  }
  setIsInitialLoading(false);
  // Set the initial filter values state
  setFilterValues(initialFilterValues);
  const initialSelectedTags = { ...initialFilterValues }
  for (const key in initialSelectedTags) {
    if (initialSelectedTags[key].some(value => value.includes('-'))) {
      delete initialSelectedTags[key];
    }
  }
  setSelectedTags(initialSelectedTags);
  setSelectedSorting(sortParam);
  // If there's a sort parameter, update the sorting state
  // if (sortParam) {
  //   setSelectedSorting(sortParam);
  // }

  // Load products based on the initial filters and sort option
  // Ensure loadProducts can handle the case where initialFilterValues may have arrays as values
  // loadProducts(productsToDisplay, 1, initialFilterValues, sortParam);
};

// Call this function when the component mounts (e.g., in useEffect in a React component)
useEffect(() => {
  initializeFiltersFromURL();
  cleanInitialFillters();
}, []);

useEffect(() => {
  // Function to reload the page
  const reloadOnSearchQueryChange = () => {
    const currentSearchQuery = window.location.search;
    if (currentSearchQuery !== searchQuery) {
      // window.location.reload();
    }
  };
// console.log("slug data",currentPath)
  // Listen for changes in the URL
  window.addEventListener('popstate', reloadOnSearchQueryChange);

  // Cleanup listener on component unmount
  return () => window.removeEventListener('popstate', reloadOnSearchQueryChange);
}, [searchQuery]);

// This function handles changes in filter values. It updates the filter values state, prepares a payload for an API call to fetch filtered products, makes the API call, and updates relevant states with the response data.
const handleFilterChange = async (filterName, value) => {
    
  setPage(2);
  setInitialLoading(false);
  setPageCount(1)
  setCurrentPage(1)
  

  // setProducts([])

  let updatedFilterValues = { ...filterValues };
  let updatedRangeValues = { ...selectedTags };
  const filterType = filters.find(f => f.filter_name === filterName).ui_type;
setFiltertTypeName(filterType);
  if (filterType === 'range') {
    if (!Array.isArray(value) || value.length !== 2) {
     
      return;
    }

    updatedFilterValues[filterName] = value.join('-');
  

} else if (filterType === 'check_box') {
  const currentFilterValues = updatedFilterValues[filterName] || [];
  if(currentFilterValues.includes(value)) {
    updatedFilterValues[filterName] = currentFilterValues.filter(v => v !== value);
    updatedRangeValues[filterName] = currentFilterValues.filter(v => v !== value);
    setSelectedTags(updatedRangeValues)
  } else {
    updatedFilterValues[filterName] = [...currentFilterValues, value];
    updatedRangeValues[filterName] = [...currentFilterValues, value];
    setSelectedTags(updatedRangeValues)
  }
}
setFilterValues(updatedFilterValues);
//  // Serialize the filter values
//  const filterParams = Object.entries(updatedFilterValues)
//  .map(([key, val]) => `${key}:${val}`)
//  .join('::');

const filterParams = Object.entries(updatedFilterValues)
.filter(([key, val]) => val !== '' && val !== null && val.length !== 0)
.map(([key, val]) => `${key}:${val}`)
.join('::');
if(window.location.search){
  // Run this function when the component mounts or when the URL changes
  const searchQuery = window.location.search;
  // Parse the search query to get the filter values and sort option
  const params = new URLSearchParams(searchQuery);
  const pagequantity = params.get('pc');
  if(pagequantity){
    var newUrl = `${window.location.pathname}?f=${filterParams}&sort=${selectedSorting}&pc=${pagequantity}`;
  }else{
    // Construct the full URL
   var newUrl = `${window.location.pathname}?f=${filterParams}&sort=${selectedSorting}`;
  }

}else{
// Construct the full URL
var newUrl = `${window.location.pathname}?f=${filterParams}&sort=${selectedSorting}`;
}


// Update the URL in the browser without encoding special characters like commas and colons
window.history.pushState({ path: newUrl }, '', newUrl);

localStorage.setItem('filterValues', JSON.stringify(updatedFilterValues));
return false

  };



    // This function is clean the fillters and fillter tags
    const cleanInitialFillters = () => {
      const url = window.location.href; // Get the current URL
      const queryString = url.split('?')[1]; // Get the query string part of the URL
      if (queryString && queryString.includes('f=')) {
//console.log('not "f="');
      }else{
        setSelectedTags({});
        // setFilterValues({});
        setSelectedSorting(sortName);
      }
  
    }
// This function is clean the fillters and fillter tags and remove the 
    const cleanFillter = () => {
      setSelectedTags({});
      setFilterValues({});
      setSelectedSorting(sortName);
      setSelectedQuantitiy(48);
      setFilterValuesData([]);

         // Get the current URL
      const currentUrl = window.location.href
let url = new URL(currentUrl);

// Clear the query string
url.search = '';

// Get the updated URL
let updatedUrl = url.toString();
// Replace the current state in the history with the updated URL
window.history.replaceState({}, '', updatedUrl);

// Update the URL in the browser
// window.location.replace(updatedUrl);
// or
// window.location.href = updatedUrl;
      // loadProducts(quantity,1)
      // setFilterValuesData([])


    }

// This function is clean the filters for mounting last selected filters
const cleanFilter = () => {
  setSelectedTags({});
  setFilterValues({});

}



// This function manages the opening and closing of filter panels. It toggles the state of a filter between open and closed.
const toggleOpenFilters = (filterName, category) => {
  // Check if the filter is already open
  const isOpen = openFilters.includes(filterName);

  // Toggle the filter's open status
  if (isOpen) {
    setOpenFilters(prevOpenFilters => prevOpenFilters.filter(f => f !== filterName));
    if(category.display_label == 'CATEGORY') {
      setDisplayCount(initialDisplayCount);
    }
  } else {
    setOpenFilters(prevOpenFilters => [...prevOpenFilters, filterName]);
  }
};

const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Enables smooth scrolling
    });
  }, 100); // Small delay to allow page updates to settle
};



//  slug is an array of segments that represent parts of the URL. The parts are extracted and stored in relevant variables - 05/08/2024.

// Ensure the slug array has the necessary parts -  05/08/2024
const category = slug[0] || ''; // e.g., 'paintings-of-india'
const subCategory = slug[1] || ''; // e.g., 'tanjore-painting'
const product = slug[2] || ''; // e.g., 'ganesha'

// Normalize the slug parts for use
const category1 = category.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
const subCategory1 = subCategory.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
const product1 = product.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');




// Function to find subcategories with a specific name and status, ignoring hyphens and underscores - 05/08/2024
function findSubcategoriesByName(menuData, subCategoryName) {
  const subcategories = [];

  // Normalize the subcategory name by removing hyphens, underscores, and converting to lowercase
  const normalizedSubCategoryName = subCategoryName.replace(/[-_]/g, '').toLowerCase();

  // Recursive function to traverse the menu
  function traverseMenu(menu) {
    for (const item of menu) {
      // Normalize the item name by removing hyphens, underscores, and converting to lowercase
      const normalizedItemName = item.name.replace(/[-_]/g, '').toLowerCase();

      if (normalizedItemName === normalizedSubCategoryName && item.status === 1) {
        subcategories.push(item);
      }
      if (item.child && item.child.length > 0) {
        traverseMenu(item.child);
      }
    }
  }

  traverseMenu(menuData);
  return subcategories;
}
 // Get the subcategories for a specific name and status, ignoring hyphens - 05/08/2024
 const subcategories = findSubcategoriesByName(menuData, subCategory1);
console.log("subcategories",subcategories);
async function fetchProductList(payload) {
  try {
      // Determine the API endpoint based on jboId
  const apiEndpoint = (jboId == 363) ? 'product/productListPage' : 'product/productListNew';
      const response = await axios.post(apiEndpoint, payload);
      if (response.data) {
        if (response.data.message === "url not found") {
          const redirectResponse = await axios.post('user/viewRedirect302', {
            // old_url: "https://qc.darjewellery.com/gold-jewellery/kids/bangle"
            old_url: window.location.href
          });
        
          if (redirectResponse.data.msg === "NO URL Found"){
            setNotFoundStatus(true);
  
            // await fetchProductList(payload); // Recursive call with updated URL
          }
          //console.log("Redirect response", redirectResponse.data.msg);
          window.location.href = redirectResponse.data.redirect[0].new_url;
          // payload.url = new URL(redirectResponse.data.redirect[0].new_url).pathname;
          
        }
        setStatus(true);
        setRedirectionStatus(response.data.message);
        const filteredProducts = response.data.products
        .map(product => {
          const useJson =jboId !== 363;   // Determine whether to use `.json` based on jboId
          return {
            id: product.product_id,
            // model product ids
            mProductId:product?.product_id1,
            name: useJson ? product.json["product_name"] : product["product_name"],
            price: useJson ? product.json.price : product.price,
            categoryName: useJson ? product.json["Category name"] : product["Category name"],
            weight: useJson ? product.json.weight : product.weight,
            image: useJson ? product.json.Images : product.Images, 
            hoverImage: product.thumbnail_image[1], // Assuming the hover image is the same, adjust if needed
            isRemoved: false,
            Availability: useJson ? product.json.Availability : product.Availability,
            subItem: useJson ? product.json.subitem_name : product.subitem_name,
            touch: product.touch, // Added touch attribute
            tagLabel: useJson ? product.json.tag_label : product.tag_label,
            productCount: product.product_count, // Added product_count attribute
            url: product.url, // Added url attribute
            specialPrice :product.special_price,  //Price Rule value 
            isSpecialPrice :product.special_price_status, // Check if the price rule is enabled or not
            priceRuleDisplay:product.price_rule_display //price rule name
          
          }
        });
        // setPageCount(Math.ceil(response.data.page_property.total_products / quantity));
        setPageCount(
          Math.ceil(response.data.page_property.total_products / response.data.page_property.page_count)
        );
        const sortOptions = response.data.sort_by; // Assuming sortOptions is part of the response
        setSortOptions(sortOptions);

        const defaultSortOption = sortOptions.find(option => option.is_default === 1);
        if (!selectedSorting && defaultSortOption) {
          setSelectedSorting(defaultSortOption.sort_name);
        }

        setTotalProducts(response.data.page_property.total_products);
        setBannerImage(response.data.page_property.banner_image);
        setQuantity(response.data.page_property.page_count);
        setProducts(filteredProducts);
        setInitialLoading(true);
        setIsLoading(false);
        setFilterLoading(false);
        const seoData = {
          title: response.data.page_property.seo_title,
          description: response.data.page_property.seo_description,
          keyword: response.data.page_property.seo_keyword
        };
  
        setSEOData(seoData);
        setPage(2);
              setFilters(response.data.filters);
            
          
        setFilterValuesData(response.data.filter_values);
        
        
        scrollToTop();

        setItemsShown(prevItemsShown => {
          const newItemsShown = { ...prevItemsShown };
          response.data.filters.forEach(filter => {
            if (filter.ui_type === 'check_box' && !newItemsShown.hasOwnProperty(filter.filter_name)) {
              newItemsShown[filter.filter_name] = 5;  // Default number of items to show
            }
          });
          return newItemsShown;
        });
    
        const savedFilterValues = JSON.parse(localStorage.getItem('filterValues'));
       
      } else {
          throw new Error('No data received from API');
      }
  } catch (error) {
    const jboID = jboId;
    const endpoint ='product/productListNew';
    const payLoad = payload;
    const responseData = error?.response?.data ? error.response.data : '';
    const errorMsg = error;
    errorLogApi({
      job_id: jboId,
      endpoint: endpoint,
      payload: payLoad,
      responseData: responseData,
      errorMsg: errorMsg
    });
   
      return null;  // Return null or appropriate error handling
  }
}

// This function makes an API call to load products based on the provided quantity and page number. It also updates relevant states with the response data.
const loadProducts = 
useCallback(async (quantity, page, sorting = selectedSorting) => {

  const url = window.location.href; // Get the current URL
const queryString = url.split('?')[1]; // Get the query string part of the URL
if (queryString && queryString.includes('f=')) {
  //console.log('The URL contains "f=" after the question mark');
   // Get the search query from the URL (e.g., '?f=Motifs:Abstract,Fish,Coins&sort=product_name_desc')
   const searchQuery = window.location.search;
  
   // Parse the search query to get the filter values and sort option
   const params = new URLSearchParams(searchQuery);
   const filterParam = params.get('f');
   var sortParam2 = params.get('sort') || sortName;
 
   // Initialize an object to hold the filter values
   var initialFilterValues = {};
   
   // Parse the filter parameters and set the initial filter values
   if (filterParam) {
     filterParam.split('::').forEach(param => {
       const [key, value] = param.split(':');
       if (key && value) {
         initialFilterValues[key] = value.includes(',') ? value.split(',') : [value];
       }
     });
   }
 
   // Set 
} else {
  //console.log('The URL does not contain "f=" after the question mark');
}

  //console.log(selectedSorting);
  const param = prepareFilterParam(initialFilterValues);
  const payload = {
    "currency": currentCurrency,
    "pc": {
      "dcount": quantity.toString(),
      // sorting state updated - 15/08/2024
      "sort_by": selectedSorting ,
      "page": page
    },
    param,
    "url": `/${category}/${subCategory}/${product}`
  }
  try {
    setIsLoading(true);
    // if(!filterTypeName) {
    //   setFilterLoading(true)
    // }
    // setFiltertTypeName('');
    // setFilterLoading(true);
    if(!isInitialLoading){
      setPage(2);
        fetchProductList(payload)
          setIsInitialLoading(false);
    
      
      }
    
    // const response = await axios.post('product/productListNew', payload);
    // //console.log("response products new",response.data.products);
    if (status){

     
    }

    
  } catch (error) {
    const jboID = jboId;
    const endpoint ='product/productListNew';
    const payLoad = payload;
    const responseData = error?.response?.data ? error.response.data : '';
    const errorMsg = error;
    errorLogApi({
      job_id: jboID,
      endpoint: endpoint,
      payload: payLoad,
      responseData: responseData,
      errorMsg: errorMsg
    });
  
    setIsLoading(false);
setFilterLoading(false);
    setProducts([]);
   
  }  

},[filterValues, selectedSorting, currentCurrency, category, subCategory, product]);
// The provided useEffect hook handles scrolling to the top of the page when the number of products to display or the selected sorting option changes.
// update the dependencies array here
useEffect(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // optional, for smooth scrolling
  });
  loadProducts(productsToDisplay, currentPage); 
}, [loadProducts]);



// useEffect(() => {
  // These functions manage changes in the number of products to display and the selected sorting option, respectively.
  const handleDisplayQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedQuantitiy(value);
    if (window.location.search) {
      const url = window.location.href;
      // Use URL constructor to parse the URL
      const urlObj = new URL(url);
  
      // Get the search parameters
      const searchParams = urlObj.searchParams;
  
        // Remove the 'pc' parameter
        searchParams.delete('pc');
      // Reconstruct the URL without the 'p' parameter
      const newUrl = `${window.location.pathname}` + '?' + searchParams.toString() + '&pc=' + value;
  
      // Update the URL in the browser without encoding special characters like commas and colons
      window.history.pushState({ path: newUrl }, '', newUrl);
    }else{
           //construct the URL without the 'p' parameter
           const newUrl = `${window.location.pathname}` + '?' + '&pc=' + value;
  
           // Update the URL in the browser without encoding special characters like commas and colons
           window.history.pushState({ path: newUrl }, '', newUrl);
    }
    setProductsToDisplay(value);
    loadProducts(value, 1);
  };
  //commented out for performance reasons
  // useEffect(() => {
  //   loadProducts(quantity, currentPage);
  // }, [currentCurrency, loadProducts]); 

  const prepareFilterParam = (updatedFilterValues) => {
    const fattr = [];
    const fval = [];

    const url = window.location.href; // Get the current URL
    const queryString = url.split('?')[1]; // Get the query string part of the URL
    if (queryString && queryString.includes('f=')) {
      //console.log('The URL contains "f=" after the question mark');

      // Push key-value pairs to fattr and fval arrays
Object.entries(updatedFilterValues).forEach(([key, value]) => {
  fattr.push(key);
  fval.push(value);
});

//console.log(fattr); // ['Motifs', 'weight']
//console.log(fval); // [['Abstract', 'Balls'], '39-195']


    } else {
      //console.log('The URL does not contain "f=" after the question mark');
      // for (const [filterName, filterValue] of Object.entries(updatedFilterValues)) {
      //   const filterType = filters.find(f => f.filter_name === filterName)?.ui_type;
    
      //   if (filterType === 'range' && filterValue) {
      //     // For range filters, use the stored range string directly
      //     fattr.push(filterName);
      //     fval.push([filterValue]);
      //   } else if (filterType === 'check_box' && filterValue.length > 0) {
      //     // For checkbox filters, use the array of selected values
      //     fattr.push(filterName);
      //     fval.push(filterValue);
      //   }
      // }
    }
  
 
  
    return { fattr, fval };
  };

  useEffect(() => {
    if (filters.length > 0) {
      const initialDisplayCounts = {};
      filters.forEach(filter => {
        initialDisplayCounts[filter.filter_name] = initialDisplayCount;
      });
      setDisplayCounts(initialDisplayCounts);
    }
  }, [filters]);


  const loadImage = async (data) => {
  
        data.forEach(item => {
            // Update Image 1 src
            const img1 = document.getElementById(item.product_id + '_main');
            if (img1) {
                img1.src = item.main;
            } else {
                
            }
    
            // Update Image 2 src
            // const img2 = document.getElementById(item.product_id + '_thumb');
            // if (img2) {
            //     img2.src = item.thumb;
            // } else {
            //     console.error(`Element with ID ${item.product_id} not found.`);
            // }
        });
    }
    //         useEffect(() => {
    //   if (products.length > 0) {
    //     const imageUrls = products.map((img) => {
    //       return {
    //         main:  setImageSrc(img.image , img.name,'s400'),
    //         thumb: setImageSrc(img.hoverImage , img.name,'s400'),
    //         product_id: img.id,
    //       };
    //     });
    //     // loadImage(imageUrls);
    //   }
    // }, [products]);

const fetchMoreProducts = async () => {

  try {

    if(initialLoading){
      if(page  <= pageCount ){
        setisLoadingIcon(true);
              // Fetch more products based on the current page
              //changed from 96 to 48 on 14-05-2024
        const newProducts = await fetchProducts(48, page);
  
        setProducts([...products, ...newProducts]);
        setPage(page + 1);
        setisLoadingIcon(false);
  
      }
      else{
        setisLoadingIcon(false);
  
      }
    }

  

  } catch (error) {

    // const jboID = jboId;
    // const endpoint ='product/productListNew';
    // const payLoad = '96';
    // const responseData = error?.response?.data ? error.response.data : '';
    // const errorMsg = error;
    // errorLogApi({
    //   job_id: jboID,
    //   endpoint: endpoint,
    //   payload: payLoad,
    //   responseData: responseData,
    //   errorMsg: errorMsg
    // });
  }
};

// Fetch more products based on page number (e.g., from an API)
const fetchProducts = async (quantity, page, sorting = selectedSorting) => {
  const param = prepareFilterParam(filterValues);
  const payload = {
    currency: currentCurrency,
    pc: {
      dcount: quantity.toString(),
      sort_by: sorting,
      page: page,
    },
    param,
    url: `/${category}/${subCategory}/${product}`,
  };
// Implement your logic to fetch more products
try{
  const apiEndpoint =  (jboId == 363 ) ? 'product/productListPage' : 'product/productListNew';

  const response = await axios.post(apiEndpoint, payload);
  // //console.log("response products new",response.data.products);
  if (response.data) {
    const filteredProducts = response.data.products.map((product) => {
      const useJson =jboId !== 363 ;   // Determine whether to use `.json` based on jboId
      return {
        id: product.product_id,
        name: useJson ? product.json["product_name"] : product["product_name"],
        price: useJson ? product.json.price : product.price,
        categoryName: useJson ? product.json["Category name"] : product["Category name"],
        weight: useJson ? product.json.weight : product.weight,
        image: useJson ? product.json.Images : product.Images, 
        hoverImage: product.thumbnail_image[1], // Assuming the hover image is the same, adjust if needed
        isRemoved: false,
        Availability: useJson ? product.json.Availability : product.Availability,
        subItem: useJson ? product.json.subitem_name : product.subitem_name,
        touch: product.touch, // Added touch attribute
        tagLabel: useJson ? product.json.tag_label : product.tag_label,
        productCount: product.product_count, // Added product_count attribute
        url : product.url,
        specialPrice :product.special_price,  //Price Rule value 
        isSpecialPrice :product.special_price_status, // Check if the price rule is enabled or not
        priceRuleDisplay:product.price_rule_display //price rule name
      
      };

      
    });
    setFilterLoading(false);
    setPageCount(
      Math.ceil(response.data.page_property.total_products / 48)
    );

    return filteredProducts;
}

}
catch (error) {

  setisLoadingIcon(false);
  setProducts([]);
}
}


const handleOptionChange = async (selectedValue) => {
  console.log('selected value', selectedValue);
  setSelectedSorting(selectedValue);

  // Get the current filter values from the existing URL or state
  let updatedFilterValues = { ...filterValues };
  let updatedRangeValues = { ...selectedTags };

  // Manually serialize the filter values without encoding
  const filterParams = Object.entries(updatedFilterValues)
    .filter(([key, val]) => val !== '' && val !== null && val.length !== 0)
    .map(([key, val]) => {
      // Keep ':' and ',' characters as they are
      const formattedVal = Array.isArray(val) ? val.join(',') : val;
      return `${key}:${formattedVal}`;
    })
    .join('::');  // Use :: as a separator between filters

  let newUrl;
  if (window.location.search) {
    const searchQuery = window.location.search;
    const params = new URLSearchParams(searchQuery);

    // Set or update the filter parameters in the URL
    if (filterParams) {
      params.set('f', filterParams);
    }
    params.set('sort', selectedValue);

    // Preserve the page quantity if it exists
    const pagequantity = params.get('pc');
    if (pagequantity) {
      newUrl = `${window.location.pathname}?${params.toString()}`;
    } else {
      newUrl = `${window.location.pathname}?${params.toString()}`;
    }
  } else {
    // Construct the URL with filters and sorting
    newUrl = `${window.location.pathname}?f=${filterParams}&sort=${selectedValue}`;
  }

  // Replace encoded characters manually to prevent unwanted encoding
  newUrl = newUrl.replace(/%3A/g, ':').replace(/%2C/g, ',');

  // Update the URL in the browser
  window.history.pushState({ path: newUrl }, '', newUrl);

  // Load products with the new sorting option
  await loadProducts(productsToDisplay, 1, selectedValue);
};





// Create the debounced version of the handleOptionChange function
const debouncedHandleOptionChange = useRef(debounce((selectedValue) => {
  handleOptionChange(selectedValue);
}, 300)).current;




// This function handles page navigation and ensures the current page isn't incremented beyond the last available page.
const handlePageClick = ({ selected: selectedPage }) => {
  // react-paginate's selectedPage starts from 0, so add 1
  var nextPage = selectedPage + 1;

  setCurrentPage(nextPage)
  // Construct the full URL
  if (window.location.search) {
    // Run this function when the component mounts or when the URL changes
    const searchQuery = window.location.search;

    // Parse the search query to get the filter values and sort option
    const params = new URLSearchParams(searchQuery);
    const filterParamPage = params.get('p');
    const filterParamValue = params.get('f');
    const filterParamSort = params.get('sort');
    const filterParamPageCount = params.get('pc');

    if (filterParamPage && (filterParamValue || filterParamSort || filterParamPageCount) ){
   
      //  var newUrl = `${window.location.pathname}?p=${nextPage}`;
      params.delete('p')
      //  var newUrl = `${window.location.pathname}${window.location.search}&p=${nextPage}`;
      var newUrl = `${window.location.pathname}` + '?' + params.toString() + `&p=${nextPage}`;



    } else if (filterParamPage) {
      // var newUrl = `${window.location.pathname}${window.location.search}&p=${nextPage}`;
      var newUrl = `${window.location.pathname}?p=${nextPage}`;
    } else {
      // var newUrl = `${window.location.pathname}${window.location.search}&p=${nextPage}`;
      //  var newUrl = `${window.location.pathname}?p=${nextPage}`;
      params.delete('p')
      //  var newUrl = `${window.location.pathname}${window.location.search}&p=${nextPage}`;
      var newUrl = `${window.location.pathname}` + '?' + params.toString() + `&p=${nextPage}`;

    }


  } else {
    var newUrl = `${window.location.pathname}?p=${nextPage}`;

  }


  // Update the URL in the browser without encoding special characters like commas and colons
  window.history.pushState({ path: newUrl }, '', newUrl);
  // Check if nextPage * quantity is greater than totalProducts, 
  // if so, don't increment currentPage.
  if (nextPage <= pageCount) {
    setCurrentPage(nextPage);
    loadProducts(quantity, nextPage);
    scrollToTop();
  }
};
  // const axios = useAxios();
  
  // These functions manage the opening and closing of the filter menu.
  const handleFilter = () => {
    //console.log("filter works")
    var menu = document.getElementById("filterMenu");

    // //console.log("menuClick", menu.classList);
    if (menu?.classList.contains("hidden")) {
      menu?.classList.remove("hidden");
    }
  };


  const filterMenuRef = useRef(); // Ref for the menu
  const closeMenuRef = useRef(); // Ref for the close button

  const filterCloseMenu = (e) => {
    // If clicked inside the close button, close the menu
    if (closeMenuRef.current && closeMenuRef.current.contains(e.target)) {
      filterMenuRef.current?.classList.add("hidden");
      return;
    }

    // If clicked outside, close the menu
    if (filterMenuRef.current && !filterMenuRef.current.contains(e.target)) {
      filterMenuRef.current?.classList.add("hidden");
    }
  };
  useEffect(() => {
    // Add the mousedown event listener when the component mounts
    document.addEventListener('mousedown', filterCloseMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', filterCloseMenu);
    };
  }, []);
    // grid issue fixed - 17/09/2024
    useEffect(() => {
      if (productSettings?.display_products_count === 2) {
        setView('twoCard');
      }
      if (productSettings?.display_products_count === 3) {
        setView('grid');
      }
    }, [productSettings]);

 if(notfoundStatus){
  return (<ProductNotFound/>)
 }

  return (
    <>
    <ErrorBoundary>
    {filterLoading && isLoading ? <LoadingAnimation/>:false}
      <SEO 
        title={SEOData?.title} 
        description={SEOData?.description} 
        keyword={SEOData?.keyword}
      />
      
    
          
      
  {/* Start Breadcrumbs */}
  {(!isMobile || !isTablet ) &&
    <div className="xl:container lg:px-4 xl:px-0 lg:py-4 md:py-10 flex justify-between flex-wrap">
      {!filterLoading && 
      
      <div className="flex gap-3 items-center ">
          <Link href="/" className="text-primary text-base px-2">
            <i className="fas fa-home"></i>
          </Link>
          <div className="flex gap-3 items-center mt-1 flex-wrap">
            <span className="text-sm text-gray-500">
              <i className="fas fa-chevron-right "></i>
            </span>
            <span className="text-gray-500  font-medium hover:text-primary "  >
      {category1}
    </span>
         
          
            {/* Dynamic url for thumbnails - 05-08-2024 */}
            {subcategories.map((subCategory) => (
              <React.Fragment key={subCategory.id}>
                <span className="text-sm text-gray-500">
                  <i className="fas fa-chevron-right "></i>
                </span>
                <Link
                  className="text-gray-500 font-medium hover:text-primary"
                  href={
                    theme.siteStatus === 0
                      ? `${subCategory.url}/?pid=${theme.token}`
                      : `${subCategory.url}`
                  }
                >
                  {subCategory.name}
                </Link>
              </React.Fragment>
            ))}
            {/* For non thumbnail category - 05/08/2024 */}
          {subcategories.length === 0 ?     <React.Fragment key={subCategory.id}>
                <span className="text-sm text-gray-500">
                  <i className="fas fa-chevron-right "></i>
                </span>
                <span
                  className="text-gray-500 font-medium hover:text-primary"
               
                >
                  {subCategory1}
                </span>
              </React.Fragment>:false }
            <span className="text-sm text-gray-500">
              <i className="fas fa-chevron-right "></i>
            </span>
            <p className="text-gray-500  font-medium ">{product1}</p>
          </div>
        </div>}
       
      </div>
    }
 
  {/* End Breadcrumbs */}
  {/* Banner image bug fix -24/10/2024 */}
  {bannerImage &&
    // productSettings?.banner_image
    <div className="mx-auto container">
    <Image
      src={setImageSrc(bannerImage, 's400')}
      alt="Banner Image"
      layout="responsive" 
      width={1600}       
      height={600}    
      className="object-cover lg:pb-8"
    />
  </div>}
  
  {/* Cover image */}
   

      {/* Start Wrapper */}
    
      
      {(isMobile || isTablet) && products.length !== 0 &&
      <div className="sticky z-50 bg-white transform translate-y-0 p-2" style={{top: stickyTop}}>
        <div>
          {/* <MobileSearchBar /> */}
        </div>
        <div className="container flex row gap-2 my-1 items-start h-11">
          
          <div className="w-1/4 border border-primary shadow-sm rounded focus:ring-primary flex justify-center items-center px-4 py-3 h-full" onClick={handleFilter}>
            <button className="flex items-center justify-center">
              <FaFilter />
              
            </button>
          </div>
          <div className="w-3/4 relative h-full">
            {/* Select component for choosing sorting option */}
            <select
  className="text-sm text-gray-600 px-4 py-3 border border-primary shadow-sm rounded focus:ring-primary focus:border-primary w-full absolute"
  onChange={(event) => {
    const selectedValue = event.target.value;
    setSelectedSorting(selectedValue); // Update state immediately
    if (theme.siteStatus === 0) {
      handleDisabledClick1(event);
    } else {
      debouncedHandleOptionChange(selectedValue); // Call debounced function
    }
  }}
  value={selectedSorting}
>
  {sortOptions.map((option) => (
    <option key={option.sort_name} value={option.sort_name}>
      {option.display_label}
    </option>
  ))}
</select>




          </div>
          <div className="w-3/6">
              <select
                    className="text-sm text-gray-600 px-4 py-3 border border-primary shadow-sm rounded focus:ring-primary focus:border-primary w-full"
                    onChange={handleDisplayQuantityChange} // Function to handle the change in display quantity
                    value={selectedQuantitiy}

               >
                    <option value="48">show  48</option>
                    <option value="96">show 96</option>
                    <option value="144"> show 144</option>
                    <option value="192">show 192</option>
                </select>
          </div>
        </div>
      </div>

        }

      <div className="lg:container px-2 lg:px-0 lg:grid lg:grid-cols-4 md:grid-cols-3 md:grid gap-6 pb-16 items-start relative">
        
        {/* Start Sidebar  */}
        {/* Start Filters sidebar */}
        {/* {!isMobile && filterLoading && (
          <>
          <div className="w-72 h-auto p-4 space-y-5 bg-gray-100 rounded shadow overflow-hidden relative">
          <div className="w-full h-6 bg-gray-300 animate-shimmer"></div>
          <div className="space-y-4">
        
            {[...Array(1)].map((_, index) => (
              <div key={index} className="w-full space-y-3">
                <div className="w-full h-5 bg-gray-300 animate-shimmer"></div>
                <div className="w-full h-4 bg-gray-300 animate-shimmer"></div>
                <div className="flex space-x-3">
                  <div className="w-4 h-4 bg-gray-300 animate-shimmer"></div>
                  <div className="w-3/4 h-4 bg-gray-300 animate-shimmer"></div>
                </div>
                <div className="w-1/2 h-4 bg-gray-300 animate-shimmer"></div>
                <div className="w-full h-4 bg-gray-300 animate-shimmer"></div>
              </div>
            ))}
          </div>
        </div>
        </>
        ) } */}

{/* // Checking if the length of products array is not zero */}
{products.length !== 0 && !filterLoading && 
  // This div is used to hold filters for the product list. It has some styles and classes applied
  <div className="col-span-1 bg-gray-25 px-4 pt-2 pb-6 shadow-sm rounded overflow-hidden absolute lg:sticky lg:top-[100px] left-4 top-16 z-10 lg:w-full lg:block hidden" id="filters">
  <div className="flex justify-between">
    <h3 className="text-xl text-gray-800 mb-3 pt-3 uppercase font-medium flex items-center cursor-pointer">
      <FaFilter className="mr-2 text-primary h-4" />
      Filters
    </h3>
    <span
      className="text-sm justify-center text-gray-800 mb-3 pt-3 pe-8 uppercase font-small flex cursor-pointer"
      onClick={(event) => {
        if (theme.siteStatus === 0) {
          handleDisabledClick(event);
        } else {
          cleanFillter(); // Assuming cleanFilter is the correct function name and doesn't require event as an argument
        }
      }}
    >
      Clear All
    </span>
  </div>
  <div className="relative overflow-y-auto overflow-x-hidden scrollbar-thumb-blue-500 scrollbar-thumb-rounded scrollbar-track-blue-100 p-5" style={{ maxHeight: 'calc(100vh - 150px)' }}>
    <div className="filters-container divide-gray-100 divide-y space-y-5">
      {/* Only render this div if there are selected tags */}
      {/* selected Tags issue fixed - 13-08-2024 */}
      {selectedTags && Object.keys(selectedTags).length > 0 && (
      <div className="flex flex-wrap gap-2 p-2">
        {Object.entries(selectedTags).map(([key, values]) => (
          values.map((value, index) => (
            <div key={index} className="group flex items-center bg-primary rounded-md text-sm px-4 py-2 text-white cursor-pointer shadow-md transition duration-150 ease-in-out transform hover:scale-105 hover:shadow-lg">
              <span className="mr-2">{value}</span>
              <AiOutlineClose
                onClick={(event) => {
                  if (theme.siteStatus === 0) {
                    handleDisabledClick(event);
                  } else {
                    handleFilterChange(key, value);

                    // Check if this is the last filter to be cleared
                    const remainingTags = { ...selectedTags };
                    remainingTags[key] = remainingTags[key].filter(v => v !== value);
                    if (Object.values(remainingTags).flat().length === 0) {
                      cleanFilter();
                    }
                  }
                }}
                className="text-lg text-white opacity-75 group-hover:opacity-100 transition-opacity duration-150 ease-in-out"
              />
            </div>
          ))
        ))}
      </div>
    )}
      {filters
        .sort((a, b) => {
          const order = { 'primary': 1, 'normal': 2, 'Secondary': 3 };
          return order[a.filter_type] - order[b.filter_type];
        })
        .map((filter) => {
          const filterData = filterValuesData.find(fv => fv.filter_name === filter.filter_name);
          if (!filterData) return null;
          const isOpen = openFilters?.includes(filter.filter_name);
          const filteredData = filterValuesData.filter(fv => fv.filter_name === filter.filter_name);
  
          const toggleShowItems = (filterName) => {
            setDisplayCounts(prevDisplayCounts => ({
              ...prevDisplayCounts,
              [filterName]: prevDisplayCounts[filterName] === initialDisplayCount ? filteredData.length : initialDisplayCount
            }));
          };
  
          return (
            <div key={filter.filter_name}>
              <h3 className="text-xl lg:text-sm xl:text-lg text-primary font-semibold mb-3 pt-3 uppercase flex justify-between cursor-pointer" onClick={() => toggleOpenFilters(filter.filter_name, filter)}>
                {filter.display_label}
                {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
              </h3>
              {isOpen && (
                <div>
                  {filter.ui_type === 'range' && (
                    <RangeSlider
                      min={filterData.min_value}
                      max={filterData.max_value}
                      filtername={filterData.filter_name}
                      value={filterValues[filter.filter_name] || filterData.min_value}
                      onChange={(newValue) => {
                        if (theme.siteStatus === 0) {
                          handleDisabledClick();
                        } else {
                          handleFilterChange(filter.filter_name, newValue);
                        }
                      }}
                    />
                  )}
                  {filter.ui_type === 'check_box' && (
                    <div>
                      {filterValuesData.filter(fv => fv.filter_name === filter.filter_name).slice(0, displayCounts[filter.filter_name]).map((filterValue, index) => (
                        <div key={index} className="flex items-center p-2 capitalize">
                          <input
                            type="checkbox"
                            id={`${filter.filter_name}_${index}`}
                            className="text-primary focus:ring-0 rounded-sm cursor-pointer"
                            checked={filterValues[filter.filter_name]?.includes(filterValue.display_label) || false}
                            onChange={(event) => {
                              if (theme.siteStatus === 0) {
                                handleDisabledClick(event);
                              } else {
                                handleFilterChange(filter.filter_name, filterValue.display_label, index);
                              }
                            }}
                          />
                          <label htmlFor={`${filter.filter_name}_${index}`} className="text-gray-600 font-roboto ml-3 cursor-pointer tracking-wide">
                            {filterValue.display_label} ({filterValue.product_count})
                          </label>
                        </div>
                      ))}
                      {filteredData.length > initialDisplayCount && displayCounts[filter.filter_name] < filteredData.length && (
                        <button className="text-primary" onClick={() => toggleShowItems(filter.filter_name)}>
                          Show more
                        </button>
                      )}
                      {displayCounts[filter.filter_name] !== initialDisplayCount && (
                        <button className="text-primary" onClick={() => toggleShowItems(filter.filter_name)}>
                          Show less
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  </div>
  </div>

}

        {/* End Filters sidebar  */}



        {/* // This div holds the product display and sorting controls */}
<div className="col-span-3 ">
  {products.length !==0 && (<>
  <div className="flex justify-center items-center">

    <h1 className="text-center text-lg text-primary uppercase">{product1}  </h1>
   &nbsp; | <span className="text-center text-lg text-primary uppercase"> &nbsp;{totalProducts} {totalProducts === 1 ? "Product":"Products"}</span>
  </div>
  </>
    )}
  {/* {products.length !==0 && (<> <h1 className="text-center text-lg text-primary uppercase">{product1} </h1><span> {totalProducts} Products</span> </h1></>)} */}
  {/* Checking if the length of products array is not zero */}
  {products.length !==0 && (!isMobile || !isTablet) && 

    // This div contains the dropdowns for sorting and display quantity, and the view mode toggles
    
    <div className="my-2 flex gap-3 md:hidden lg:flex">
      {!isTablet && 

      <div className="flex gap-3">
        {/* Select component for choosing sorting option */}
  

<select
            className="text-sm text-gray-600 px-4 border border-primary shadow-sm rounded focus:ring-primary focus:border-primary"
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedSorting(selectedValue); // Update state immediately
              if (theme.siteStatus === 0) {
                handleDisabledClick1(event);
              } else {
                debouncedHandleOptionChange(selectedValue); // Call debounced function
              }
            }}
            value={selectedSorting}
        >
           {sortOptions.map((option) => (
        <option key={option.sort_name} value={option.sort_name}>
          {option.display_label}
        </option>
      ))}
        
        </select>


              {/* // Dropdown to select the quantity of items to display */}
      <select
        className="w-44 text-sm text-gray-600 px-4 py-3 border border-primary  shadow-sm rounded
                focus:ring-primary focus:border-primary hidden lg:block"
        onChange={handleDisplayQuantityChange} // This function handles the change in display quantity
        value={selectedQuantitiy}
     >
                                                      <option value="48">Display 48</option>
        <option value="96">Display 96</option>
        <option value="144">Display 144</option>
        <option value="192">Display 192</option>
      </select>
      </div>}
      


      {/* // Dropdown to select the sorting method */}
    
      {/* // Start toggle: these two divs allow the user to toggle between grid view and list view */}
      <div className="flex gap-2 ml-auto">

        {/* // List view toggle */}
        {productSettings?.is_single_product_view === 1 &&
        <div className={`border ${view === 'list' ? 'bg-primary text-white' : 'border-gray-300 text-gray-600'} shadow-sm w-10 h-9 lg:flex hidden items-center justify-center rounded cursor-pointer`}
          onClick={() => setView('list')} // This function changes the view to list view
        >
          <FaList />
        </div>}

        {/* Two card view */}
        <div className={`border ${view === 'twoCard' ? 'bg-primary text-white' : 'border-gray-300 text-gray-600'} w-10 h-9 shadow-sm lg:flex hidden items-center justify-center rounded cursor-pointer`}
          onClick={() => setView('twoCard')} // This function changes the view to grid view
        >
          <FaThLarge />
        </div>

        {/* // Three card view */}
        <div className={`border ${view === 'grid' ? 'bg-primary text-white' : 'border-gray-300 text-gray-600'} w-10 h-9 shadow-sm lg:flex hidden items-center justify-center rounded cursor-pointer`}
          onClick={() => setView('grid')} // This function changes the view to grid view
        >
        <FaTh />
        </div>

        <div className={`border ${view === 'fourCard' ? 'bg-primary text-white' : 'border-gray-300 text-gray-600'} w-10 h-9 shadow-sm xl:flex hidden items-center justify-center rounded cursor-pointer`}
          onClick={() => setView('fourCard')} // This function changes the view to grid view
        >
        <CgViewMonth size={30}/>
        </div>
        
      </div>
    </div>
  }

  {/* // This div holds the product list and the pagination controls */}
  <div >

{
    <Products   products={products} isLoading={isLoading} view={view} filterLoading={filterLoading} productSettings={productSettings} />
}
    {products.length !== 0 &&      //for pagination
    <div>
      <Pagination 
        pageCount={pageCount} 
        onPageChange={handlePageClick}
        totalProducts={totalProducts}
        quantity={quantity}
        currentPage={currentPage}  // no need to add 1 here, as you've already adjusted it in handlePageClick
      />
      </div>
    }
</div>

{/* This code activate the Lazy loading Start */}

{/* <div>
<InfiniteScroll
          style={{overflow:"hidden"}}
          dataLength={products.length}
          next={fetchMoreProducts}
          scrollThreshold={"3000px"}
          hasMore={true}
          loader={isLoadingIcon ? <LoadingAnimation/> :
          <> 
{products.length !== 0 && !isLoading &&
        <div className="text-center flex justify-center mt-4">
        <button
        onClick={scrollToTop}
          className="flex items-center justify-center px-4 py-2 my-1 bg-primary text-white font-semibold rounded-lg shadow-mdfocus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-75"
        >
          <MdArrowUpward className="mr-2" /> Back to Top
        </button>
      </div>
          }
      </>}
        > 
  
    <Products   products={products} isLoading={isLoading} view={view} filterLoading={filterLoading} productSettings={productSettings} />
  
    </InfiniteScroll>
</div> */}


    {/* This code activate the Lazy loading End */}


</div>


        {/* End Product */}
      </div>

      {/* End Wrapper */}

{/* Start Mobile Filter Menu */}
<div
    className="fixed left-0 top-0 w-full md:w-[50%] h-full z-50 bg-black bg-opacity-30 
            shadow hidden" // CSS styles to position the div at the full screen with a black background
    id="filterMenu" // HTML id for filter menu
    ref={filterMenuRef}
>
    {/* Actual filter menu */}
    <div className="absolute left-0 top-0 w-full h-full z-50 bg-white shadow max-h-screen overflow-y-auto flex-grow">
        
        {/* Close button for filter menu */}
        <div
        className="text-gray-200 font-bold text-lg absolute right-3 top-4 cursor-pointer"
        id="filterCloseMenu"
        ref={closeMenuRef} // Attach the ref to your close button
        onClick={filterCloseMenu} // Function to handle the closing of the filter menu
      >
            {/* Icon for the close button */}
            <i className="fas fa-times"></i>
        </div>
        
        {/* Heading for the filter menu */}
        <h2 className="text-xl font-semibold text-white px-3 mb-2 pl-4 pt-4 pb-4 bg-primary">
            {/* Filter icon */}
            <FaFilter className="inline-block mr-2"/> Filters
        </h2>

        {/* Filters */}
        <div className="filters-container p-5 divide-gray-300 divide-y space-y-5 relative">
  {filters
    .sort((a, b) => {
      const order = { 'primary': 1, 'normal': 2, 'secondary': 3 };
      return order[a.filter_type] - order[b.filter_type];
    })
    .map((filter) => {
      const filterData = filterValuesData.find(fv => fv.filter_name === filter.filter_name);

      // Skip rendering the filter if no corresponding filterValuesData entry is found
      if (!filterData) return null;

      const isOpen = openFilters?.includes(filter.filter_name);
      const filteredData = filterValuesData.filter(fv => fv.filter_name === filter.filter_name);

      const toggleShowItems = (filterName) => {
        setDisplayCounts(prevDisplayCounts => ({
          ...prevDisplayCounts,
          [filterName]: prevDisplayCounts[filterName] === initialDisplayCount ? filteredData.length : initialDisplayCount
        }));
      };

      return (
        <div key={filter.filter_name}>
          <h3 className="text-xl lg:text-lg text-primary font-semibold mb-3 pt-3 uppercase flex justify-between cursor-pointer" onClick={() => toggleOpenFilters(filter.filter_name, filter)}>
            {filter.display_label}
            {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
          </h3>

          {isOpen && (
            <div>
              {filter.ui_type === 'range' && (
                <>
                 <RangeSlider
  min={filterData.min_value}
  max={filterData.max_value}
  value={filterValues[filter.filter_name] || filterData.min_value}
  onChange={(newValue) => {
    if (theme.siteStatus === 0) {
      // Call a version of handleDisabledClick that doesn't require an event object
      // Or adapt handleDisabledClick to handle being called without an event
      handleDisabledClick();
    } else {
      handleFilterChange(filter.filter_name, newValue);
    }
  }}
/>

                </>
              )}

              {filter.ui_type === 'check_box' && (
                <div>
                   {filterValuesData.filter(fv => fv.filter_name === filter.filter_name).slice(0, displayCounts[filter.filter_name]).map((filterValue, index) => {
                      // Only display items up to the current display count
                      // if (index < displayCount) {
                        return (
                          <div key={index} className="flex items-center p-2 capitalize">
                           <input
  type="checkbox"
  id={`${filter.filter_name}_${index}`}
  className="text-primary focus:ring-0 rounded-sm cursor-pointer"
  checked={filterValues[filter.filter_name]?.includes(filterValue.display_label) || false}
  onChange={(event) => {
    if (theme.siteStatus === 0) {
      handleDisabledClick(event);
    } else {
      handleFilterChange(filter.filter_name, filterValue.display_label, index);
    }
  }}
/>

                            <label htmlFor={`${filter.filter_name}_${index}`} className="text-gray-600 font-roboto ml-3 cursor-pointer tracking-wide">
                              {filterValue.display_label} ({filterValue.product_count})
                            </label>
                          </div>
                        );
                      // }
                    })}
                    <div className="text-left">
                    {filteredData.length > initialDisplayCount && displayCounts[filter.filter_name] < filteredData.length && (
                      <button className="text-primary" onClick={() => toggleShowItems(filter.filter_name)}>
                        Show more
                      </button>
                    )}
                    {displayCounts[filter.filter_name] !== initialDisplayCount && (
                      <button className="text-primary" onClick={() => toggleShowItems(filter.filter_name)}>
                        Show less
                      </button>
                    )}
                    </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    })}
</div>


    </div>
</div>
{/* End Mobile Filter Menu */}
</ErrorBoundary>
<CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        confirmLabel={popupConfig.confirmLabel}
        cancelLabel={popupConfig.cancelLabel}
        onConfirm={popupConfig.onConfirm}
        showCloseIcon={popupConfig.showCloseIcon}
        onClose={popupConfig.onClose}
        autoClose={popupConfig.autoClose}
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
      />
    </>
  );
};





export default Shop;
